<template>
  <v-card data-intercom-target="PrescriptionList">
    <v-card-title>
      <p class="tw-text-blue-900 tw-text-2xl tw-font-bold">
        Prescription List (Last 365 days)
      </p>
      <p class="tw-text-blue-900 tw-text-2xl ">
        (Select upto 3 Medications)
      </p>
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>
    <v-data-table
      v-model="selectedMedication"
      :search="search"
      :footer-props="{'items-per-page-options': [10, 20, 30, 40, 50, -1]}"
      disable-items-per-page
      :headers="headers"
      :items="detailedMedicationsList"
      :items-per-page="30"
      item-key="RxNumber"
      :single-select="singleSelect"
      show-select
      fixed-header
      event
      sort-by="RxNumber"
      :loading="loading"
      :sort-desc="[true]"
      @click:row="rowClick"
    >
      <template v-slot:header.data-table-select>
      </template>
      <template
        #[`item.selectedDrug`]="{ item }"
      >
        <v-icon
          color="success"
          medium
          class="me-3"
          @click="selectRow(item)"
        >
          {{ icons.mdiPencilOutline }}
        </v-icon>
      </template>
      <template
        v-for="header in headers.filter((header) =>
          header.hasOwnProperty('formatter')
        )"
        v-slot:[`item.${header.value}`]="{ header, value }"
      >
        {{ header.formatter(value) }}
      </template>
      <template #[`item.hold`]="{item}">
        <v-chip
          :hidden="!item.hold"
          small
          class="v-chip-light-bg"
          :color="statusList[item.hold] || ''"
          :class="`${statusList[item.hold]}--text`"
        >
          {{ translateList[item.hold] }}
        </v-chip>
      </template>
      <template #[`item.Status`]="{item}">
        <v-chip
          :hidden="!item.Status"
          small
          class="v-chip-light-bg"
          :color="statusList[item.Status] || ''"
          :class="`${statusList[item.Status]}--text`"
        >
          {{ translateList[item.Status] }}
        </v-chip>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { mdiPencilOutline } from '@mdi/js'
import { momentTimeZone } from '@/util/functions/index'
import { mapGetters, mapState } from 'vuex'

export default {
  name: 'MutliDrugMax3',
  props: ['selected'],
  setup() {
    const statusColor = { 1: 'warning' }

    return { statusColor }
  },
  data() {
    return {
      statusList: {
        1: 'orange',
        COM: 'success',
        AMD: 'success',
        CAN: 'warning',
        HOL: 'orange',
        I: 'error',
        F: 'primary',
        CH: 'warning',
        T: 'grey',
      },
      translateList: {
        1: 'Hold',
        COM: 'Complete',
        AMD: 'Amended',
        CAN: 'Cancelled',
        HOL: 'Hold',
        I: 'Inactive',
        F: 'New Rx',
        CH: 'CAN/HOL',
        T: 'Transferred Out',
      },
      loading: false,
      detailedMedicationsList: [],
      selectedMedication: this.selected || [],
      search: '',
      RxNumber: '',
      singleSelect: false,
      rowIndex: [],

      // groupby: 'GenericName',
      headers: [
        {
          text: 'Date',
          value: 'RxDate',
          groupable: false,
          formatter: x => (x ? momentTimeZone(x) : null),
        },
        {
          text: 'Brand Name',
          value: 'BrandName',
        },
        {
          text: 'Generic Name',
          value: 'GenericName',
          sortable: false,
        },
        {
          text: 'Strength',
          value: 'RxStrength',
          sortable: false,
          groupable: false,
        },
        {
          text: 'Dr Last Name',
          value: 'LastName',
        },
        {
          text: 'Rx Number',
          value: 'RxNumber',
          groupable: false,
        },
        {
          text: 'Rx Status',
          value: 'Status',
          groupable: false,
        },
        {
          text: 'Rx Hold',
          value: 'hold',
          groupable: false,
        },
      ],
      icons: {
        mdiPencilOutline,
      },
    }
  },
  watch: {
    selectedMedication(val, oldVal) {
      if (val.length > 3) {
        this.$nextTick(() => {
          console.log(this.selectedMedication)
          this.selectedMedication = oldVal
        })
      }
      this.$emit('medicationupdatelist', this.selectedMedication)
    },

    // selectedMedication() {
    //   this.$emit('medicationupdatelist', this.selectedMedication)
    // },
  },
  created() {
    this.getSearchByRx()
  },
  methods: {
    rowClick(item, row) {
      if (this.rowIndex.includes(row.index)) {
        this.rowIndex = this.rowIndex.filter(rowIndex => rowIndex !== row.index)
        row.select(false)
      } else {
        row.select(true)
        this.rowIndex.push(row.index)
      }
    },
    async getSearchByRx() {
      this.loading = true
      this.detailedMedicationsList =
        (await this.$root.$system.DBAdapterConn.getSingleRecentRx(
          this.PatientProfile.PatientID,
          this.rxNumber,
          this.genericName,
          this.brandName,
          this.mixName,
          this.LastName,
          this.FirstName,
        )) || []
      this.loading = false
    },
    select() {
      this.$emit('medicationupdatelist', this.selectedMedication)
    },
  },
  computed: {
    ...mapGetters(['PatientProfile']),
    ...mapState(['User']),
  },
}
</script>
