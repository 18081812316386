var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"data-intercom-target":"PrescriptionList"}},[_c('v-card-title',[_c('p',{staticClass:"tw-text-blue-900 tw-text-2xl tw-font-bold"},[_vm._v(" Prescription List (Last 365 days) ")]),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{attrs:{"search":_vm.search,"footer-props":{'items-per-page-options': [10, 20, 30, 40, 50, -1]},"disable-items-per-page":"","headers":_vm.headers,"items":_vm.detailedMedicationsList,"items-per-page":30,"item-key":"RxNumber","single-select":_vm.singleSelect,"show-select":"","fixed-header":"","event":"","sort-by":"RxNumber","sort-desc":[true],"loading":_vm.loading},on:{"click:row":_vm.rowClick},scopedSlots:_vm._u([{key:"item.selectedDrug",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"me-3",attrs:{"color":"success","medium":""},on:{"click":function($event){return _vm.selectRow(item)}}},[_vm._v(" "+_vm._s(_vm.icons.mdiPencilOutline)+" ")])]}},_vm._l((_vm.headers.filter(function (header) { return header.hasOwnProperty('formatter'); }
      )),function(header){return {key:("item." + (header.value)),fn:function(ref){
      var header = ref.header;
      var value = ref.value;
return [_vm._v(" "+_vm._s(header.formatter(value))+" ")]}}}),{key:"item.hold",fn:function(ref){
      var item = ref.item;
return [_c('v-chip',{staticClass:"v-chip-light-bg",class:((_vm.statusList[item.hold]) + "--text"),attrs:{"hidden":!item.hold,"small":"","color":_vm.statusList[item.hold] || ''}},[_vm._v(" "+_vm._s(_vm.translateList[item.hold])+" ")])]}},{key:"item.Status",fn:function(ref){
      var item = ref.item;
return [_c('v-chip',{staticClass:"v-chip-light-bg",class:((_vm.statusList[item.Status]) + "--text"),attrs:{"hidden":!item.Status,"small":"","color":_vm.statusList[item.Status] || ''}},[_vm._v(" "+_vm._s(_vm.translateList[item.Status])+" ")])]}}],null,true),model:{value:(_vm.selectedMedication),callback:function ($$v) {_vm.selectedMedication=$$v},expression:"selectedMedication"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }