<template>
  <v-row>
    <v-col cols="12">
      <v-menu
        ref="dateInput"
        v-model="menu"
        transition="scale-transition"
        offset-y
        :return-value.sync="refState[refKey]"
        :nudge-right="40"
        max-width="290px"
        min-width="290px"
      >
        <template v-slot:activator="{on}">
          <v-text-field
            v-model="refState[refKey]"
            dark
            :label="label"
            prepend-icon="event"
            readonly
            color="white"
            v-on="on"
          >
          </v-text-field>
        </template>
        <v-date-picker

          v-model="refState[refKey]"
          reactive
          no-title
          scrollable
          @input="$refs.dateInput.save(refState[refKey])"
        >
        </v-date-picker>
      </v-menu>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    refState: {
      type: Object,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    refKey: {
      type: String,
      required: true,
    },
    value: {
      type: [String, Object],
    },
  },
  data() {
    return {
      menu: false,
    }
  },
  created() {
    if (this.value) {
      this.refState[this.refKey] = this.value
    }
  },
}
</script>

<style scoped>

</style>
