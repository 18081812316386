<template>
  <v-card>
    <v-card-title>
      <p class="tw-text-blue-900 tw-text-2xl tw-font-bold dark:tw-text-white dark:tw-text-opacity-50">
        Please select a doctor from the list below:
      </p>
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>
    <v-data-table
      v-model="selectedDoctor"
      :search="search"
      :footer-props="{'items-per-page-options': [10, 20, 30, 40, 50, -1]}"
      disable-items-per-page
      :headers="headers"
      :items="doctorList"
      :items-per-page="30"
      item-key="DoctorSelectId"
      :single-select="singleSelect"
      show-select
      fixed-header
      event
      :loading="loading"
      @click:row="rowClick"
    >
      <template slot="no-data">
        <tr>
          <td
            colspan="6"
            class="pa-4"
          >
            No doctors to select from
          </td>
        </tr>
      </template>
      <template
        #[`item.selectedDoctor`]="{ item }"
      >
        <v-icon
          color="success"
          medium
          class="me-3"
          @click="selectRow(item)"
        >
          {{ icons.mdiPencilOutline }}
        </v-icon>
      </template>
      <template
        v-for="header in headers.filter((header) =>
          header.hasOwnProperty('formatter')
        )"
        v-slot:[`item.${header.value}`]="{ header, value }"
      >
        {{ header.formatter(value) }}
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { mdiPencilOutline } from '@mdi/js'
import { mapGetters, mapState } from 'vuex'

export default {
  props: ['selectedDoc'],
  data() {
    return {
      loading: false,
      dateFormat: 'YYYY-MM-DD',
      detailedMedicationsList: [],
      selectedDoctor: this.selectedDoc || [],
      search: '',
      DoctorSelectId: '',
      singleSelect: true,
      doctorList: [],
      rowIndex: '',

      // groupby: 'GenericName',
      headers: [
        {
          text: 'Last Name',
          class: 'black--text font-weight-bold subtitle-1',
          align: 'left',
          value: 'DoctorLastName',
          sortable: false,
        },
        {
          text: 'First Name',
          class: 'black--text font-weight-bold',
          align: 'left',
          value: 'DoctorFirstName',
          sortable: false,
        },
        {
          text: 'CPSO',
          class: 'black--text font-weight-bold',
          align: 'left',
          value: 'CPSO',
          sortable: false,
        },
        {
          text: 'Address',
          class: 'black--text font-weight-bold',
          align: 'left',
          value: 'DoctorAddress',
          sortable: false,
        },
        {
          text: 'Phone',
          class: 'black--text font-weight-bold',
          align: 'left',
          value: 'DoctorPhone',
          sortable: false,
        },
        {
          text: 'Fax',
          class: 'black--text font-weight-bold',
          align: 'left',
          value: 'DoctorFax',
          sortable: false,
        },
        {
          text: 'Prescriptions in Last Year',
          class: 'black--text font-weight-bold',
          align: 'left',
          value: 'RXperProvider',
          sortable: false,
        },
      ],
      icons: {
        mdiPencilOutline,
      },
    }
  },
  watch: {
    selectedDoctor() {
      this.$emit('doctorupdatelist', this.selectedDoctor)
    },
  },
  created() {
    this.searchDoctor()
  },
  methods: {
    rowClick(item, row) {
      if (this.rowIndex === '') {
        this.rowIndex = row.index
        row.select(true)
      } else if (this.rowIndex === row.index) {
        row.select(false)
        this.rowIndex = ''
      } else {
        this.rowIndex = row.index
        row.select(true)
      }
    },
    async searchDoctor() {
      this.loading = true
      this.doctorList =
        (await this.$root.$system.DBAdapterConn.getSearchByDoctor(
          this.PatientProfile.PatientID,
          this.drFirstName,
          this.drLastName,
          this.cpso,
        )) || []
      this.loading = false
    },
    select() {
      this.$emit('doctorupdatelist', this.selectedDoctor)
    },
  },
  computed: {
    ...mapGetters(['PatientProfile']),
    ...mapState(['User']),
  },
}
</script>
